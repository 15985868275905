import React, {FC, useEffect, useState} from 'react';
import {navigate} from '@reach/router';
import {useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import {Translate} from '../../components/translations';

import {USER_VERIFY} from '../../graphql/mutations';
import {UserVerifyComponentProps, UserVerifyMutationData} from '../../types';
import {setUserToken, setUserHash} from '../../helpers';

const PrivacyPolicy: FC<UserVerifyComponentProps> = ({token}) => {
  const [verifyMutation, {data}] = useMutation<UserVerifyMutationData>(USER_VERIFY);
  const [errorMessage, setError] = useState('');

  useEffect(() => {
    token && token !== 'index.html' && verifyMutation({variables: {verifyCode: token}});
  }, []);

  useEffect(() => {
    if (data?.UserOps?.verify) {
      const {error, message, token, hash} = data.UserOps.verify;
      if (error) {
        setError(message);
      } else {
        setUserToken(token);
        setUserHash(hash);
        if (localStorage.getItem('selected-plan') && token) {
          navigate('/pricing/checkout/');
        } else {
          navigate('/pricing/');
        }
      }
    }
  }, [data]);

  //@ts-ignore
  const searchQuery =
    global &&
    global.window &&
    global.window.location &&
    global.window.location.search &&
    global.window.location.search.split('&');
  let success = '';
  let error = '';
  let user_token = '';
  let user_hash = '';
  searchQuery &&
    searchQuery.find((item: any) => {
      if (item.indexOf('token') > -1) {
        user_token = item.slice(item.indexOf('=') + 1, item.length);
        user_token && setUserToken(user_token);
      }
      if (item.indexOf('hash') > -1) {
        user_hash = item.slice(item.indexOf('=') + 1, item.length);
        user_hash && setUserHash(user_hash);
      }
      if (item.indexOf('success') > -1) {
        success = item.slice(item.indexOf('=') + 1, item.length);
      }
      if (item.indexOf('error') > -1) {
        error = item.slice(item.indexOf('=') + 1, item.length);
      }
    });
  useEffect(() => {
    success &&
      setTimeout(() => {
        if (localStorage.getItem('selected-plan')) {
          navigate('/pricing/checkout/');
        } else {
          navigate('/pricing/');
        }
      }, 5000);
  }, []);

  return (
    <Layout>
      <div className="content-wrapper">
        <PageHero title="VERIFY_SUBTITLE" />
        <Section>
          <div className="col-lg-12 text-center">
            {!token || token === 'index.html' ? (
              error ? (
                <div className="alert alert-danger">
                  <Translate name={'Invalid or expired verification link'} />
                </div>
              ) : (
                success && (
                  <>
                    <p>You will be automatically redirected to your dashboard in 5 seconds</p>
                    <div className="alert alert-success">
                      {success && <Translate name={'Email has been successfully verified'} />}
                    </div>
                  </>
                )
              )
            ) : errorMessage ? (
              <div className="alert alert-danger">
                <Translate name={errorMessage} />
              </div>
            ) : (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </div>
        </Section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
